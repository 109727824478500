import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from '../../components/CaptionedImage';
import Instabug from '../../components/Instabug';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Update (12/26/2019): A previous version of this post incorrectly stated implict animations happen in layers which back `}<inlineCode parentName="p">{`UIView`}</inlineCode>{`s. Actually, layers which back `}<inlineCode parentName="p">{`UIView`}</inlineCode>{`s don't have animations unless you implement the view's `}<inlineCode parentName="p">{`action(for:forKey:)`}</inlineCode>{` method. More information is available in `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGUIViewLayerBackgroundColorTest"
        }}>{`this github repo`}</a>{`.`}</p>
    </blockquote>
    <p>{`Recently, I've been learning more about Metal - I'm still working through the basics, but I've written a couple of `}<a parentName="p" {...{
        "href": "/blog/gpu-capture-flickering"
      }}>{`posts`}</a>{` and `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1197599674917515264"
      }}>{`tweets`}</a>{` about it, and I'm about halfway through `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/MetalByExampleExamples"
      }}>{`Metal By Example`}</a>{`. Up until now I've mostly been coding sample projects, but I recently had the opportunity to prototype a Metal implementation integrated into our production app at work.`}</p>
    <p>{`One thing I hadn't considered until I had to implement Metal in a real app was animations - when another part of our interface animated, we wanted to scale the Metal content along with an animation. This post will dive into how to adapt the CALayer animation system to work with a custom Metal view, and along the way we'll see a situation which Swift can't handle without hacks. (😱)`}</p>
    <p>{`Our goal will be to build a triangle that animates to a new scale when a button is pressed:`}</p>
    <CaptionedImage max={400} filename="calayer-metal-1.gif" alt="Blue triangle animating from 1x to 2x scale when a button is clicked" caption="" mdxType="CaptionedImage" />
    <blockquote>
      <p parentName="blockquote">{`Full sample code is available for this project at `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGCAMetalLayerAnimationExample"
        }}>{`NGCAMetalLayerAnimationExample`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h2>
    <p>{`Our sample project will follow the architecture adapted from the Xcode default Metal project (what Xcode gives you after New Project > Cross Platform Game):`}</p>
    <ol>
      <li parentName="ol">{`We'll have a `}<inlineCode parentName="li">{`Renderer`}</inlineCode>{` with a `}<inlineCode parentName="li">{`draw(drawable:)`}</inlineCode>{` method which is responsible for issuing Metal draw calls with the `}<inlineCode parentName="li">{`drawable`}</inlineCode>{` as a target. The renderer will have a `}<inlineCode parentName="li">{`triangleScale`}</inlineCode>{` property to determine what transform to the triangle's vertices.`}</li>
      <li parentName="ol">{`We'll have a custom view with a `}<a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/quartzcore/cametallayer"
        }}><inlineCode parentName="a">{`CAMetalLayer`}</inlineCode></a>{` subclass, and we'll override the layer's `}<inlineCode parentName="li">{`display()`}</inlineCode>{` to call `}<inlineCode parentName="li">{`draw`}</inlineCode>{`, passing the drawable from `}<a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/quartzcore/cametallayer/1478172-nextdrawable"
        }}><inlineCode parentName="a">{`nextDrawable`}</inlineCode></a>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Note: we need a `}<inlineCode parentName="p">{`CAMetalLayer`}</inlineCode>{` instead of an `}<a parentName="p" {...{
          "href": "https://developer.apple.com/documentation/metalkit/mtkview"
        }}><inlineCode parentName="a">{`MTKView`}</inlineCode></a>{` here, for reasons I'll get into near the end of this post.`}</p>
    </blockquote>
    <p>{`Our custom layer looks like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`CustomCAMetalLayer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: CAMetalLayer {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` renderer: Renderer!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`device`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`MTLCreateSystemDefaultDevice`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`renderer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Renderer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`device`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`device`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`!)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`setNeedsDisplay`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`/// Block the current thread until this layer has a drawable ready.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`blockRequestingNextDrawable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() -> CAMetalDrawable {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` drawable: CAMetalDrawable? = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`while`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (drawable == `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            drawable = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`nextDrawable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` drawable!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`display`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` drawable = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`blockRequestingNextDrawable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`renderer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`draw`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`drawable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: drawable)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`MetalView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UIView {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` layerClass: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`AnyClass`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` CustomCAMetalLayer.self`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`We set up our renderer in init, wait for a drawable to become available, and use our renderer to draw the triangle. The renderer code is more verbose since Metal requires quite a lot of code to set up a command queue, vertex buffers, etc, but you can view the code `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGCAMetalLayerAnimationExample/blob/master/NGCAMetalLayerAnimationExample%20iOS/GameViewController.swift#L29"
      }}>{`here`}</a>{` if you like - it creates a buffer of three vertices for the points of the triangle, and the fragment shader shades them blue.`}</p>
    <CaptionedImage max={400} filename="calayer-metal-2.png" alt="Blue triangle displaying on the screen" caption="Hello World" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "calayer-animations"
    }}>{`CALayer animations`}</h2>
    <p>{`In order to implement our triangle's scale-up animation, we need to talk more about CoreAnimation.`}</p>
    <p><a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/quartzcore"
      }}>{`CoreAnimation`}</a>{` is the framework that powers `}<inlineCode parentName="p">{`CALayer`}</inlineCode>{` (used to implement `}<inlineCode parentName="p">{`UIView`}</inlineCode>{`). Changes in a `}<inlineCode parentName="p">{`CALayer`}</inlineCode>{` (like setting a new position or background color) are initiated by changing the layer's properties.`}</p>
    <p>{`Many layer properties (you can see a list `}<a parentName="p" {...{
        "href": "https://developer.apple.com/library/archive/documentation/Cocoa/Conceptual/CoreAnimation_guide/AnimatableProperties/AnimatableProperties.html#//apple_ref/doc/uid/TP40004514-CH11-SW4"
      }}>{`here`}</a>{`) are animatable, which means that when they're changed, CoreAnimation will interpolate between the old value and the new value to animate the change. If you've ever tried to change the background color of a `}<inlineCode parentName="p">{`CALayer`}</inlineCode>{` directly (using `}<inlineCode parentName="p">{`backgroundColor`}</inlineCode>{`), you might notice that the change isn't instant - there's a subtle fade. Animatable `}<inlineCode parentName="p">{`CALayer`}</inlineCode>{` properties all have default, "implicit" animations.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: layers which back `}<inlineCode parentName="p">{`UIView`}</inlineCode>{`s don't have these implicit animations by default since `}<inlineCode parentName="p">{`UIView`}</inlineCode>{`s don't provide an action through their layer delegate method. More on this later.`}</p>
    </blockquote>
    <p>{`We'll add our `}<inlineCode parentName="p">{`triangleScale`}</inlineCode>{` as a custom layer property - we want this property to animate between 1 and 2 to double the size of the triangle.`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`CustomCAMetalLayer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: CAMetalLayer {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` triangleScale: CGFloat`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`display`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`renderer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Float`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(triangleScale)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Our button callback sets the new value:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@objc`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`didTapButton`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    layer.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`And when we run the app and click the button, we get...`}</p>
    <CaptionedImage max={400} filename="calayer-metal-3.gif" alt="Blue triangle displaying with cursor clicking button, but nothing happens with the triangle after button click" caption="" mdxType="CaptionedImage" />
    <p>{`...nothing!`}</p>
    <h2 {...{
      "id": "customizing-layer-animations"
    }}>{`Customizing layer animations`}</h2>
    <p>{`Turns out, custom CALayer properties don't come completely for free. I haven't been able to find an official Apple reference on this, but there are several articles online about how to implement them - I'd recommend `}<a parentName="p" {...{
        "href": "https://www.objc.io/issues/12-animations/animating-custom-layer-properties/"
      }}>{`this objc.io article`}</a>{` and `}<a parentName="p" {...{
        "href": "https://vimeo.com/44986916"
      }}>{`this talk from Rob Napier`}</a>{`, but I'll try to summarize here.`}</p>
    <p>{`In order for CoreAnimation to manage our custom layer properties, they have to be Objc `}<inlineCode parentName="p">{`@dynamic`}</inlineCode>{` properties. Declaring an `}<inlineCode parentName="p">{`@dynamic`}</inlineCode>{` property in Objc tells the compiler that the property implementation will be managed dynamically - in this case, since we're a subclass of `}<inlineCode parentName="p">{`CALayer`}</inlineCode>{`, CoreAnimation will manage this property for us, and we don't have to declare getters or setters, or handle initializing the property. (Learn more about `}<inlineCode parentName="p">{`@dynamic`}</inlineCode>{` in the `}<a parentName="p" {...{
        "href": "https://developer.apple.com/library/archive/documentation/Cocoa/Conceptual/ObjectiveC/Chapters/ocProperties.html#//apple_ref/doc/uid/TP30001163-CH17-SW2"
      }}>{`Objective-C reference`}</a>{`.)`}</p>
    <p>{`The dynamic nature of how CALayer handles property animations is why we need to use a `}<inlineCode parentName="p">{`CAMetalLayer`}</inlineCode>{` instead of an `}<inlineCode parentName="p">{`MTKView`}</inlineCode>{` - if we were to declare these properties on a view subclass instead of a layer subclass, they wouldn't be automatically handled in the same way.`}</p>
    <p>{`There's another wrinkle though - Swift doesn't support managing dynamic properties in the same way. There's a `}<inlineCode parentName="p">{`dynamic`}</inlineCode>{` keyword in Swift, but that specifies that `}<a parentName="p" {...{
        "href": "https://swiftunboxed.com/interop/objc-dynamic/"
      }}>{`function calls should use Objc-style dynamic method dispatch`}</a>{`, not that property implementations should be managed dynamically.`}</p>
    <p><inlineCode parentName="p">{`@dynamic`}</inlineCode>{` is a mechanism in Objc that just doesn't exist in Swift. Luckily, there's a way to get around it - `}<inlineCode parentName="p">{`@NSManaged`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "dynamic-properties-in-swift"
    }}>{`Dynamic properties in Swift`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`@NSManaged`}</inlineCode>{` property modifier is used for Core Data, but its effect is the same as Objc's `}<inlineCode parentName="p">{`@dynamic`}</inlineCode>{` - it defers implementation of the property getters and setters. If we declare our layer property as `}<inlineCode parentName="p">{`@NSManaged`}</inlineCode>{`, `}<inlineCode parentName="p">{`CALayer`}</inlineCode>{` will be able to manage its getters and setters! Our new code looks like:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`CustomCAMetalLayer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: CAMetalLayer {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@NSManaged`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` triangleScale: CGFloat`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`display`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`renderer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Float`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(triangleScale)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Now that we've declared our property dynamically, the last step is to tell CoreAnimation that we want the layer to be redisplayed when the property changes. We do this by overriding `}<inlineCode parentName="p">{`needsDisplay(forKey:)`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`needsDisplay`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`key`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Bool`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` key == `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"triangleScale"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`needsDisplay`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: key)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`We have a triangle that changes scale! The only thing left is to actually implement the animation.`}</p>
    <CaptionedImage max={400} filename="calayer-metal-4.gif" alt="Blue triangle changing scale (not animated) when the button is clicked." caption="" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "using-presentation-layers"
    }}>{`Using presentation layers`}</h2>
    <p>{`In CoreAnimation, each layer is actually composed of a "model" layer, which represents the current set of non-interpolated properties, and a "presentation" layer, which represents the layer's current state as it appears on screen. Copies of the model and presentation layers are accessible through `}<inlineCode parentName="p">{`CALayer`}</inlineCode>{`'s `}<inlineCode parentName="p">{`model()`}</inlineCode>{` and `}<inlineCode parentName="p">{`presentation()`}</inlineCode>{` methods.`}</p>
    <p>{`As an example: let's say we added an animation for our triangleScale, to take its value from 1 to 2.`}</p>
    <p>{`The first thing we have to do is make sure our `}<inlineCode parentName="p">{`CALayer`}</inlineCode>{` subclass knows how to be instantiated as a presentation copy - CoreAnimation will call `}<inlineCode parentName="p">{`init(layer:)`}</inlineCode>{` for this.`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`layer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Any`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`layer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: layer)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`guard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` layer = layer as? CustomCAMetalLayer `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`renderer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = layer.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`renderer`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Then we add an explicit animation:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` animation = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CABasicAnimation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`keyPath`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"triangleScale"`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`fromValue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`toValue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`duration`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.25`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`metalView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`layer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`add`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(animation, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"some-key"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`And a print in our layer's `}<inlineCode parentName="p">{`display()`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` modelScale = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`model`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`().`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`guard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` presentationScale = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`presentation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()?.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"model: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`\\(`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`modelScale`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`, presentation: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`\\(`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`presentationScale`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`We'll get the following output:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.0053806598298252`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.1318122893571854`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.2192756980657578`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.2894128262996674`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.363368809223175`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.4336175322532654`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.5070415139198303`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.577137529850006`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.649360716342926`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.7197566032409668`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.7918232679367065`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.8647624254226685`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.9373475313186646`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.0`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 1.0, presentation: 1.0`}</span></code></pre>
    <p>{`The model layer never changes, and the presentation layer's `}<inlineCode parentName="p">{`triangleScale`}</inlineCode>{` is automatically interpolated from 1 to 2 - but changes back to 1 after the animation ends, since CoreAnimation starts using the model value again when the animation ends. Before we see the triangle growing, we'll have to fix this interpolation issue.`}</p>
    <h2 {...{
      "id": "custom-animatable-properties"
    }}>{`Custom animatable properties`}</h2>
    <p>{`I noted before that with CoreAnimation, animatable layer properties have default animations that apply when you change the property. We can define our own animation by returning a value from the `}<inlineCode parentName="p">{`action(forKey:)`}</inlineCode>{` class method:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`action`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`key`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) -> CAAction? {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` key == `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"triangleScale"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` animation = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CABasicAnimation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`keyPath`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: key)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`fromValue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`presentation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()?.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` animation`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`action`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: key)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}       `}</span></span></code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note: specifying the fromValue here is important, but other animation properties (duration, timing function, etc) will be inherited from the current `}<a parentName="p" {...{
          "href": "https://developer.apple.com/documentation/quartzcore/catransaction"
        }}><inlineCode parentName="a">{`CATransaction`}</inlineCode></a>{`.`}</p>
    </blockquote>
    <p>{`If we specify an action, we don't need to define an animation anymore - CoreAnimation will automatically add the animation we defined when the layer's property changes. Adding the animation is now:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`guard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` layer = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`metalView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`layer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` as? CustomCAMetalLayer `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`layer.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span></span></code></pre>
    <p>{`Our model layer stays at 2 while the presentation layer's value is interpolated all the way there.`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.0`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.0715526789426804`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.1949102729558945`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.27173313498497`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.3447068929672241`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.4154288470745087`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.4875067472457886`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.5570306777954102`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.628233015537262`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.7017306685447693`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.771639108657837`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.8431110382080078`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.9160330295562744`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 1.9892664551734924`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`model: 2.0, presentation: 2.0`}</span></code></pre>
    <h2 {...{
      "id": "the-last-step"
    }}>{`The last step`}</h2>
    <p>{`We've got our presentation layer set up to interpolate the triangle's scale, so the last step is to pass it to the renderer. We'll change our layer's display function to use the presentation value:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`display`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`guard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` effectiveScale = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`presentation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()?.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`renderer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Float`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(effectiveScale)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` drawable = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`blockRequestingNextDrawable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`renderer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`draw`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`drawable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: drawable)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Specify a few parameters for the animation:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`CATransaction.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`begin`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    CATransaction.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`setAnimationDuration`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    CATransaction.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`setAnimationTimingFunction`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(.`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`easeInEaseOut`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    layer.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`CATransaction.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`commit`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span></code></pre>
    <p>{`And tada! 🎉`}</p>
    <CaptionedImage max={400} filename="calayer-metal-1.gif" alt="Blue triangle animating from 1x to 2x scale when the button is clicked" caption="" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "more-complicated-animations"
    }}>{`More complicated animations`}</h2>
    <p>{`Using CALayer properties to implement our animations means that we can hook into the entire CoreAnimation ecosystem - our `}<inlineCode parentName="p">{`triangleScale`}</inlineCode>{` is now every bit as animatable as every other `}<inlineCode parentName="p">{`CALayer`}</inlineCode>{` property. One benefit is that we get keyframe animations with no extra work! Let's make the triangle jump around a bit:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` layer = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`metalView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`layer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` as! CustomCAMetalLayer`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` animation = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CAKeyframeAnimation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`keyPath`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"triangleScale"`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`values`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = [`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    layer.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`-1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`-2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    layer.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`triangleScale`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`keyTimes`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = [`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.5`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.8`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`timingFunctions`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = [`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    .`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`easeInEaseOut`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`),`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    .`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`easeInEaseOut`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`),`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    .`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`easeInEaseOut`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`),`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    .`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`easeInEaseOut`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`animation.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`duration`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`8`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`metalView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`layer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`add`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(animation, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"expandScale"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <CaptionedImage max={400} filename="calayer-metal-5.gif" alt="Blue triangle animating between multiple different scales with a keyframe animation" caption="" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "conclusion-and-further-reading"
    }}>{`Conclusion and further reading`}</h2>
    <p>{`CoreAnimation was always one of the more confusing parts of iOS development to me, but hopefully this post has helped to pull back the covers a bit on how the system works - going through these examples has certainly helped me understand how to make Metal play nicely with other parts of the iOS ecosystem.`}</p>
    <p>{`There's a lot of great Apple and non-Apple content written about custom CALayer animations `}<em parentName="p">{`not`}</em>{` related to Metal that I'd recommend checking out:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.objc.io/issues/12-animations/animating-custom-layer-properties/"
        }}>{`Animating Custom Layer Properties`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vimeo.com/44986916"
        }}>{`Cocoaheads June 2012: Animating Custom Layer Properties`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://academy.realm.io/posts/tryswift-tim-oliver-advanced-graphics-with-core-animation/"
        }}>{`Advanced Graphics with CoreAnimation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nsscreencast.com/episodes/290-core-graphics-custom-calayer"
        }}>{`Custom CALayer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/library/archive/documentation/Cocoa/Conceptual/CoreAnimation_guide/AdvancedAnimationTricks/AdvancedAnimationTricks.html"
        }}>{`Advanced Animation Tricks`}</a></li>
    </ul>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      